<template>
  <div ref="gantt"></div>
</template>
<script>
import "dhtmlx-gantt";
import "dhtmlx-gantt/codebase/locale/locale_cn.js";
import "dhtmlx-gantt/codebase/ext/dhtmlxgantt_marker.js";
import "dhtmlx-gantt/codebase/ext/dhtmlxgantt_tooltip.js";

export default {
  name: "gantt",
  props: {
    isShowLeft: {
      type: Boolean,
      default: true
    },
    tasks: {
      type: Object,
      default() {
        return { data: [], links: [] };
      },
    },
      newSpos: {
      type: Object,
      default() {
        return { x: 0, y: 0 };
      },
    },
  },
  data() {
    return {
      options: [
        { value: "0", label: "0" },
        { value: "10", label: "10" },
        { value: "20", label: "20" },
        { value: "30", label: "30" },
        { value: "40", label: "40" },
        { value: "50", label: "50" },
        { value: "60", label: "60" },
        { value: "70", label: "70" },
        { value: "80", label: "80" },
        { value: "90", label: "90" },
        { value: "100", label: "100" },
      ],
      priorityoptions: [
        { key: this.$i18n.t("label.low"), label: this.$i18n.t("label.low") }, // 低
        { key: this.$i18n.t("label.middle"), label: this.$i18n.t("label.middle") }, // 中
        { key:this.$i18n.t("label.height"), label:this.$i18n.t("label.height") }, // 高
        { key: this.$i18n.t("label.tabpage.nothingz"), label: this.$i18n.t("label.tabpage.nothingz") }, // 无
      ],
      billingoptions: [
        { key: "asap", label: this.gantt.locale.labels.asap },
        { key: "alap", label: this.gantt.locale.labels.alap },
        { key: "snet", label: this.gantt.locale.labels.snet },
        { key: "snlt", label: this.gantt.locale.labels.snlt },
        { key: "fnet", label: this.gantt.locale.labels.fnet },
        { key: "fnlt", label: this.gantt.locale.labels.fnlt },
        { key: "mso", label: this.gantt.locale.labels.mso },
        { key: "mfo", label: this.gantt.locale.labels.mfo },
      ],
      statusoptions:[{
          value: this.$i18n.t("label.projectManagement.planned"), // 已计划
          label: this.$i18n.t("label.projectManagement.planned")
        }, {
          value: this.$i18n.t("label.projectManagement.dispatched"), // 已派遣
          label: this.$i18n.t("label.projectManagement.dispatched")
        }, {
          value: this.$i18n.t("label.starting"), // 进行中
          label: this.$i18n.t("label.starting")
        }, {
          value: this.$i18n.t("label.projectManagement.unable.to.complete"), // 无法完成
          label: this.$i18n.t("label.projectManagement.unable.to.complete")
        }, {
          value: this.$i18n.t("label.finished"), // 已完成
          label: this.$i18n.t("label.finished")
        }, {
          value: this.$i18n.t("label.projectManagement.cancelled"), // 已取消
          label: this.$i18n.t("label.projectManagement.cancelled")
        },{
          value: this.$i18n.t("label.tabpage.nothingz"), // 无
          label: this.$i18n.t("label.tabpage.nothingz")
        }]
    };
  },
  methods: {
    addtype(event){
      let domobj= event.path || (event.composedPath && event.composedPath());
      this.$parent.addtype(domobj[0].getAttribute(':type'),domobj[0].getAttribute(':id'))
    },
    riskBox(event) {
      
      let domobj= event.path || (event.composedPath && event.composedPath());
      this.$parent.add(domobj[0].getAttribute(':type'),domobj[0].getAttribute(':id'));
    },
    edit(event){
      event.stopPropagation()
      let domobj= event.path || (event.composedPath && event.composedPath());
      this.$parent.edit(domobj[0].getAttribute(':type'),domobj[0].getAttribute(':id'));
    },
    //隐藏“+”
    hide() {
      this.gantt.templates.grid_row_class = function (start, end, task) {
        if (task.$level > 1) {
          return "nested_task";
        }
        return "";
      };
    },
    //重新加在数据
    reload() {
      this.gantt.clearAll();
      this.addTodayLine();
      this.gantt.parse(this.$props.tasks);
      this.gantt.render();
      this.gantt.scrollTo(this.newSpos.x,this.newSpos.y) // 滚动条滚动到指定位置
    },
    addTodayLine() {
      // 时间线
      var date_to_str = this.gantt.date.date_to_str(this.gantt.config.task_date);
      var today = new Date();
      this.gantt.addMarker({
        start_date: today,
        css: "today",
        text: this.$i18n.t("component.timeline.label.today"), // 今天
        title: `${this.$i18n.t("component.timeline.label.today")}: ` + date_to_str(today),  // 今天
      });
    },
    
    //初始化数据视图
    init() {
      let that=this
      this.gantt.config.drag_project = true;
      this.gantt.templates.tooltip_date_format = function (date) {
            var formatFunc = this.gantt.date.date_to_str("%Y-%m-%d %H:%i");
            return formatFunc(date);
        };
      this.gantt.templates.tooltip_text = function (task) {
        let name=''
        switch(task.type){
          case 'CloudccProject': name=that.$i18n.t("label.file.item"); break;  // 项目
          case 'serviceappointment': name=that.$i18n.t("label.projectManagement.service.appointment"); break; // 服务预约
          case 'cloudccMilestone': name=that.$i18n.t("component.milestones.label.title"); break; // 里程碑
          case 'cloudccTask': name=that.$i18n.t("label.projectManagement.project.task"); break; // 项目任务
          case 'cloudccSubtask': name=that.$i18n.t("label.projectManagement.subtasks"); break; // 子任务
          default: name=that.$i18n.t("label_partner_new_step3_name"); break  // 名称
        }
        // 开始时间: that.$i18n.t("label.import.index.starttime")
        // 结束时间: that.$i18n.t("component.event.label.enddate")
        // 负责人: that.$i18n.t("label_person_in_charge")
        // 完成百分比: that.$i18n.t("label.projectManagement.percent.complete")
        return `<b>${name}:</b><a href="/#/commonObjects/detail/${task.id}/DETAIL?sign=back">${task.text}</a><br/><b>${that.$i18n.t("label.import.index.starttime")}:</b> ${this.getCurrentTime1(task.start_date)}<br/><b>${that.$i18n.t("label.endtime")}:</b> ${ this.getCurrentTime(task.end_date)}<br/><b>${that.$i18n.t("label_person_in_charge")}:</b> ${task.user}<br/><b>${that.$i18n.t("label.projectManagement.percent.complete")}:</b>${Math.round(task.progress*10)*10}%`};
      // gantt.config.tooltip_hide_timeout = 2000; // 提示浮窗消失延迟时间
      this.gantt.config.tooltip_offset_x = 3;
      this.gantt.config.tooltip_offset_y = 4;
      // gantt.config.tooltip_timeout=1000;
      this.gantt.config.drag_links = false; //依赖不可拖拽
      this.gantt.config.drag_progress = true; //任务的百分比不可拖动
      this.gantt.config.preserve_scroll = false; //在重新绘制甘特图时保留垂直和水平滚动的当前位置
      this.gantt.config.xml_date = "%Y-%m-%d";
      this.gantt.config.autofit = false;
      this.gantt.config.details_on_dblclick = false;
      this.gantt.config.show_errors = false; //发生异常时，不允许弹出警告到UI界面
      this.gantt.config.scales = [
        {
          unit: "month",
          step: 1,
          format: function (date) {
            // 年：that.$i18n.t("label.login.access.year")
            // 月：that.$i18n.t("label.login.access.month")
            return date.getFullYear() + that.$i18n.t("label.login.access.year") + (date.getMonth() + 1) + that.$i18n.t("label.login.access.month");
          },
        },
        { unit: "day", step: 1, format: "%j" },
      ];
      this.gantt.config.row_height = 50;
      this.gantt.config.min_column_width = 18;
      this.gantt.config.autoscroll = true; // 自动滚动
      this.gantt.config.readonly = false;
      this.gantt.config.work_time = false; //起始和结束时间遇到周末的时候，自动把时间改为工作日的时间
      this.gantt.config.correct_work_time = false; //起始和结束时间遇到周末的时候，自动把时间改为工作日的时间
      // gantt.config.start_date = new Date(2020, 4, 30); ////设置图表区域的日期坐标最小值
      // gantt.config.end_date = new Date(2023, 11, 31); //设置图表区域的日期坐标最大值
      this.gantt.config.open_tree_initially = true; //初始化的时候就展开树结构
      // 配置甘特图头部高度
      this.gantt.config.scale_height = 50;
      // 配置行高度
      this.gantt.config.row_height = 38;
      // gantt.config.min_grid_column_width = 200;
      //左右出现滚动条
      this.gantt.config.layout = {
        css: "gantt_container",
        cols: [
          {
            width: !this.isShowLeft ? 1 : 400,
            // width: 1,
            min_width: 300,
            rows: [
              {
                view: "grid",
                scrollX: "gridScroll",
                scrollable: true,
                scrollY: "scrollVer",
              },
              { view: "scrollbar", id: "gridScroll", group: "horizontal" },
            ],
          },
          { resizer: true, width: 1 },
          {
            rows: [
              { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
              { view: "scrollbar", id: "scrollHor", group: "horizontal" },
            ],
          },
          { view: "scrollbar", id: "scrollVer" },
        ],
      };
      //设置周末
      this.gantt.templates.scale_cell_class = function (date) {
        if (date.getDay() == 0 || date.getDay() == 6) {
          return "weekend";
        }
      };
      this.gantt.templates.timeline_cell_class = function (item, date) {
        if (date.getDay() == 0 || date.getDay() == 6) {
          return "weekend";
        }
      };
      // 设置今天时间
      // var date_to_str = this.gantt.date.date_to_str(this.gantt.config.task_date);
      // var today = new Date();
      //可编辑的列
      // var textEditor = { type: "text", map_to: "text" };
      var startdateEditor = {
        type: "date",
        map_to: "start_date",
        min: new Date(2020, 0, 1),
        max: new Date(2025, 0, 1),
      };
      var enddateEditor = {
        type: "date",
        map_to: "Nextname",
        min: new Date(2020, 0, 1),
        max: new Date(2025, 0, 1),
      };
      // var durationEditor = {
      //   type: "select",
      //   map_to: "Nextid",
      //   options: this.options,
      // };
      // var priorityEditor = {
      //   type: "select",
      //   map_to: "priority",
      //   options: this.priorityoptions,
      // };
      // var billingEditor = {
      //   type: "select",
      //   map_to: "billing",
      //   options: this.billingoptions,
      // };
      // var statusEditor = {
      //   type: "select",
      //   map_to: "status",
      //   options: this.statusoptions,
      // }
      this.gantt.config.columns = [
        { name: "", label: "", width: 50, align: "center" ,
         template: function (task) {
           if(task.type == "CloudccProject"|| task.type == "cloudccMilestone"){
             return `<div onclick="riskBox(event)" :type='${task.type}' :id='${task.id}' style="font-size:24px">
             +
            </div>`;
           }
          //  if(task.type == "cloudccMilestone"){
          //     return `<div onClick="addtype()" :type='${task.type}' :id='${task.id}' style="font-size:24px">
          //    +
          //   </div>`;
          //  }
          },
        },
        //设置列
        {
          name: "text",
          label: this.$i18n.t("label.quote.title"), // 标题
          width: 200,
          tree: true,
          align: "left",
         template: function (task) {
            if(task.number) return `<div style="width:100px;overflow: hidden;
               text-overflow:ellipsis;
               white-space: nowrap;">${task.number}.${task.text}</div>`;
            return `<div style="width:100px;overflow: hidden;
               text-overflow:ellipsis;
               white-space: nowrap;">${task.text}</div>`
          },
        },
        {
          name: "user",
          label: this.$i18n.t("label_person_in_charge"), // 负责人
          align: "left",
          width: 150,
          template: function (task) {
            return `<div style="height:50px;display:flex;align-items:center"><div style="width:120px;overflow: hidden;
               text-overflow:ellipsis;
               white-space: nowrap;">${task.user}</div>
              <svg class="icon editImgGantt edit" onclick="edit(event)" :type='${task.type}' :id='${task.id}' aria-hidden="true" >
                    <use href="#icon-editPens" onclick="edit(event)" :type='${task.type}' :id='${task.id}'></use>
                  </svg> 
            
            </div>`;
          },
        },
        {
          name: "start_date",
          label: that.$i18n.t("label.import.index.starttime"), // 开始时间
          width: 150,
          align: "center",
          editor: startdateEditor,
        },
        {
          name: "Nextname",
          label: that.$i18n.t("label.endtime"), // 结束时间 
          width: 150,
          align: "center",
          editor: enddateEditor,
        },
        {
          name: "Nextid",
          label:`${that.$i18n.t("label.projectManagement.percent.complete")}(%)`, // 完成百分比(%)
          align: "left",
          width: 100,
           template: function (task) {
            if(task.type!=="serviceappointment")return `<div style="height:100px">${task.Nextid}
            <i class="el-icon-caret-bottom" style="cursor: pointer;" onclick="Open(event)" :type='${task.type}' :id='${task.id}' :status='${task.Nextid}'></i></div>`;
          },
        },
        { 
          name: "status", 
          label: that.$i18n.t("label.import.index.state"),  // 状态
          width: 100, 
          align: "center",
          template: function (task) {
            if(task.status&&task.type=="serviceappointment")return `<div style="height:100px">${task.status}
            <i class="el-icon-caret-bottom"  onclick="Open(event)" :type='${task.type}' :id='${task.id}' :status='${task.status}'></i></div>`;
            if(task.status)return `<div style="height:100px">${task.status}</div>`
          },
        },
        {
          name: "continue_date",
          label: that.$i18n.t("label.projectManagement.planned.days"), // 计划天数(天)
          align: "center",
          width: 100,
        },
        {
          name: "priority",
          label: that.$i18n.t("label.chatter.priority"), // 优先级
          width: 80,
          align: "left",
        },
        {
          name:"billing",
          label: that.$i18n.t("label.projectManagement.charging"), //计费
          width: 100,
          align: "center",
        },
      ];
      //进度条上显示
      this.gantt.templates.task_text = function (start, end, task) {
        if(task.type==='serviceappointment'){
          return task.text + "----" + task.user;
        }else{
          return Math.round(task.progress*10)*10+'%   '+task.text + "----" + task.user;
        }
        
      };
      this.gantt.config.show_grid = true;
      this.gantt.config.show_progress = true;
      this.gantt.config.preserve_scroll = false; //重新绘制甘特图时保留垂直和水平滚动的当前位置
      this.gantt.init(this.$refs.gantt);
      // 设置鼠标悬浮气泡
      // this.setGanttTooltip(gantt);
      this.gantt.parse(this.$props.tasks);
    },
    getCurrentTime (date) {
      let Y = date.getFullYear()
      let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
      let D = date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate()
      date = Y + '-' + M + '-' + D 
      return this.getNextDate(date,-1)
   },
   getCurrentTime1 (date) {
      let Y = date.getFullYear()
      let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)
      let D = date.getDate() < 10 ? ('0' + date.getDate()) : date.getDate()
      date = Y + '-' + M + '-' + D 
      return date
   },
  //减少一天
  getNextDate(date, day) {
      var dd = new Date(date);
      dd.setDate(dd.getDate() + day);
      var y = dd.getFullYear();
      var m =
        dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1;
      var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate();
      return y + "-" + m + "-" + d;
    },
    //新建任务或者服务预约
  // radiobutton1(){
  //       var radios=document.getElementsByName("radiobutton1");
  //       for(var i=0;i<radios.length;i++){
  //        if(radios[i].checked==true)
  //        {
  //        this.$parent.add(event.path[0].getAttribute(':type'),event.path[0].getAttribute(':id'),radios[i].value);
  //       }
  //     }
  //   },
    Open(event){
      // 解决火狐浏览器兼容问题
      let domobj= event.path || (event.composedPath && event.composedPath());
      if(domobj[0].getAttribute(':type')=='serviceappointment'){
      this.$parent.Editstatus(domobj[0].getAttribute(':status'),domobj[0].getAttribute(':id'),domobj[0].getAttribute(':type'),this.statusoptions)
      }else{
      this.$parent.Editstatus(domobj[0].getAttribute(':status'),domobj[0].getAttribute(':id'),domobj[0].getAttribute(':type'),this.options)
      }
    },
    // bigImg(){
    //   
    //   var imgs = document.querySelectorAll(".edit")
    //   // 
    //   for(let i in imgs){
    //     
    //     if(i == event.path[0].getAttribute(':index')){
    //       // 
    //       imgs[i].style.display = "block"
    //     }
    //   }
    // }
  },
  mounted() {
    let that=this
    window.Open = this.Open
    window.getCurrentTime1 = this.getCurrentTime1
    // window.bigImg = this.bigImg
    // window.radiobutton1 = this.radiobutton1
    window.getCurrentTime = this.getCurrentTime
    window.riskBox = this.riskBox;
    window.edit = this.edit
    window.addtype = this.addtype
    this.init();
    this.hide();
    this.gantt.createDataProcessor((entity, action, data, id) => {
      this.$emit(`${entity}-updated`, id, action, data,event.target.className);
    });
       // 当鼠标移到甘特容器上时触发,此时记录滚动条位置
    this.gantt.attachEvent("onMouseMove", function(){
      let newSpos = this.gantt.getScrollState(); // 获取滚动条位置
       that.$emit('change-spos',newSpos)
    });
    //禁用删除依赖弹窗
    this.gantt.attachEvent("onLinkDblClick", function(id,e){
      this.gantt.getLink(e);
    });
    
    // 选中事件
    this.gantt.attachEvent("onTaskSelected", (id) => {
      let task = this.gantt.getTask(id);
      this.$bus.$emit("taskupdate", task, event.target.className);
    });
  },
};
</script>

<style>
@import "~dhtmlx-gantt/codebase/dhtmlxgantt.css";
.gantt_task_progress {
  /* background-color: #03aedd; */
}
/* 任务线 */
.gantt_task_line {
  height: 20px !important;
  line-height: 20px !important;
  margin-top: 6px;
  border: none !important;
}
/* 里程碑 */
.gantt_task_line.gantt_milestone {
  width: 30px !important;
  height: 30px !important;
  border: none !important;
  background-color: inherit !important;
  background-size: cover;
}
.gantt_task_line {
  border-radius: 10px;
  /* margin-top: 19px; */
}
.editImgGantt{
  margin-left:5px;
  width: 14px;
  height: 14px;
  cursor: pointer;
}
/* 里程碑icon */
.gantt_task_line.gantt_milestone .gantt_task_content {
  margin-top: -8px;
  transform: rotate(0deg) !important;
  background-image: none;
  background-size: 100%;
}
/* 左侧悬浮单元格背景色 */
.gantt_grid_data .gantt_row.odd:hover,
.gantt_grid_data .gantt_row:hover {
  background-color: #f5f5f5 !important;
}
/* 选中一列背景色 */
.gantt_grid_data .gantt_row.gantt_selected,
.gantt_grid_data .gantt_row.odd.gantt_selected,
.gantt_task_row.gantt_selected {
  background-color: #f5f5f5 !important;
}
/* 选中一列每个单元格右border颜色 */
.gantt_task_row.gantt_selected .gantt_task_cell {
  border-right-color: #f5f5f5 !important;
}
/* 日历区域除表头外给周末设置样式 */
.gantt_task_row .gantt_task_cell.weekend {
  background-color: #f3f2f3 !important;
}
/* 日历区域给表头周末设置样式 */
.gantt_task .gantt_task_scale .gantt_scale_cell.weekend {
  /* color: #fac1cf; */
}
/* 修改表头行高 */
/* 配置项中可以配置高度，不用样式改变 */
/* .gantt_grid_scale, .gantt_task_scale{
  height: 50px !important;
  line-height: 50px !important;
}
.gantt_scale_line{
   height: 25px !important;
  line-height: 25px !important; 
}
.gantt_scale_line{
  height: 25px !important;
  line-height: 25px !important;
} */
/* 修改甘特图行 */
/* 任务名前的logo */
.gantt_tree_icon.gantt_file,
.gantt_tree_icon.gantt_folder_open,
.gantt_tree_icon.gantt_folder_closed {
  width: 10px;
  background-image: none;
}

/* .gantt_tree_content{overflow: hidden;white-space: nowrap;text-overflow: ellipsis;} */

/* 标记线 */
.gantt_marker {
  background-color: #e5851a;
  /* opacity: 0.4; */
}
/*滚动条整体样式*/
.ganttContainer ::-webkit-scrollbar {
  width: 14px; /*宽分别对应竖滚动条的尺寸*/
  height: 10px; /*高分别对应横滚动条的尺寸*/
}

/*滚动条里面轨道*/
.ganttContainer ::-webkit-scrollbar-track {
  border-radius: 0 !important;
  background-color: rgba(0, 0, 0, 0.2);
  background: #d8d8d8;
}
/*滚动条里面拖动条*/
.ganttContainer ::-webkit-scrollbar-thumb {
  background-color: #969696;
  border-radius: 0 !important;
}
.gantt_grid_head_cell {
  /* font-size:16px; */
  color: black !important;
}
.nested_task .gantt_add {
  display: none !important;
}
/* 甘特图百分比拖动按钮样式 */
    /* .gantt_task_line .gantt_task_progress_drag{
      height: 25px;
      margin-left: -1px;
      width: 1px;
    } */
</style>
