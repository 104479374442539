<!-- cardPro -->
<template>
  <div class="cardPro">
    <el-card class="box-card">
      <div slot="header" class="clearfix cardHead">
        <div class="hoverBtnBox" v-if="showDownIcon">
          <el-popover
            placement="bottom"
            v-model="visible"
            width="200"
            trigger="hover"
          >
            <DownList
              @handleItem="handleItem"
              :loading="downLoading"
              :itemlist="downItemlist"
              :key="key"
              @searchDownList="searchDownList"
            />
            <div class="downIcon" slot="reference">
              <span>{{ cardTitle }}</span>
              <i class="el-icon-caret-bottom"></i>
            </div>
          </el-popover>
        </div>
      </div>
      <div class="cardContainer">
        <!-- 暂无数据时显示这个div -->
        <div class="noData" v-if="!chartOptions">
          <temporarily-no-data :titleWord="titleWord" :imgSrc="imgSrc" />
        </div>

        <v-chart
          v-else
          ref="creditChart"
          autoresize
          :options="chartOptions"
          class="chartContainer"
        ></v-chart>
      </div>
    </el-card>
  </div>
</template>

<script>
// 图表配置项
import * as chartConfig from "./config/chartConfig";
import DownList from "./downList";
import TemporarilyNoData from "@/components/TemporarilyNoData/index.vue"; // 暂无数据
//api
import * as projectApi from "./api.js";
export default {
  name: "cardPro",
  components: { DownList, TemporarilyNoData },
  data() {
    return {
      chartOptions: null,
      cardTitle: "",
      showDownIcon: true,
      visible: false,
      downItemlist: [
        {
          name: this.$i18n.t("label.projectManagement.recent.projects"),
          id: "currentPro",
        },
      ], //最近的项目
      key: "",
      downLoading: false,
      titleWord: "",
      imgSrc: "",
    };
  },
  props: {
    chartItem: {
      type: Object,
      default: () => {},
    },
  },
  computed: {},
  watch: {
    visible(val) {
      if (val) {
        this.key = 1;
      } else {
        this.key = 2;
        this.downItemlist = [
          {
            name: this.$i18n.t("label.projectManagement.recent.projects"),
            id: "currentPro",
          },
        ]; //最近的项目
        this.downLoading = false;
      }
    },
  },
  methods: {
    searchDownList(val) {
      if (val) {
        this.downLoading = true;
        this.getDownList({ name: val }, "getProjectInfo");
      } else {
        this.downItemlist = [
          {
            name: this.$i18n.t("label.projectManagement.recent.projects"),
            id: "currentPro",
          },
        ]; //最近的项目
      }
    },
    handleItem(val) {
      this.visible = false;
      if (val.id === "currentPro") {
        switch (this.chartItem.i) {
          case "Achievement-card1": //我的项目
            this.getData({ name: "" }, "getProjectInfo", "homeOption1");
            break;
          case "CardView-card4": //完成的任务
            this.getCompletedTask(
              { projectId: "" },
              "getCompletedTask",
              "homeOption2"
            );
            break;
          case "CardView-card9": //问题状态
            this.getProblemStatus(
              { projectId: "" },
              "getProblemStatus",
              "homeOption4"
            );
            break;
          case "Achievement-card2": //资源管理
            this.getMemberWorkHour(
              { projectId: "" },
              "getMemberWorkHour",
              "homeOption5"
            );
            break;
          default:
            break;
        }
      } else {
        switch (this.chartItem.i) {
          case "Achievement-card1": //我的项目
            this.getIdProjectInfo({ id: val.id }, "homeOption1");
            break;
          case "CardView-card4": //完成的任务
            this.getCompletedTask(
              { projectId: val.id },
              "getCompletedTask",
              "homeOption2"
            );
            break;
          case "CardView-card9": //问题状态
            this.getProblemStatus(
              { projectId: val.id },
              "getProblemStatus",
              "homeOption4"
            );
            break;
          case "Achievement-card2": //资源管理
            this.getMemberWorkHour(
              { projectId: val.id },
              "getMemberWorkHour",
              "homeOption5"
            );
            break;
          default:
            break;
        }
      }
    },
    // 查询下拉项目数据
    getDownList(params, apiType) {
      projectApi[apiType](params)
        .then((res) => {
          if (res.data?.length > 0) {
            this.downItemlist = res.data.map((item) => {
              return item;
            });
          }
          this.downLoading = false;
        })
        .catch(() => {
          this.downItemlist = [];
          this.downLoading = false;
        });
    },
    // 查询图表接口数据
    getData(params, apiType, chartOption) {
      projectApi[apiType](params)
        .then((res) => {
          this.chartOptions = null;
          if (res.data?.length > 0) {
            this.chartOptions = chartConfig[chartOption];
            let data1 = [],
              data2 = [];
            res.data.forEach((item) => {
              data1.push(
                item.completePercentage
                  ? item.completePercentage.split("%")[0] * 1
                  : 0
              );
              data2.push(item.name);
            });
            this.chartOptions.series[0].data = data1;
            this.chartOptions.xAxis[0].data = data2;
            this.chartOptions.title.text = this.$i18n.t(
              "label.projectManagement.percent.complete"
            );
          }
        })
        .catch(() => {
          this.chartOptions = null;
        });
    },
    // 查询资源分配接口数据
    getMemberWorkHour(params, apiType, chartOption) {
      projectApi[apiType](params)
        .then((res) => {
          this.chartOptions = null;
          if (res.data?.length > 0) {
            this.chartOptions = chartConfig[chartOption];
            let data1 = [],
              data2 = [];
            res.data.forEach((item) => {
              data1.push(item.member_name);
              data2.push(item.continue_date);
            });
            this.chartOptions.series[0].data = data2.reverse();
            this.chartOptions.yAxis[0].data = data1.reverse();
            this.chartOptions.xAxis[0].name = this.$i18n.t("label.chatter.day");
            this.chartOptions.series[0].itemStyle.normal.label.formatter =
              "{c}" + this.$i18n.t("label.chatter.day"); //y轴显示数据及单位
          }
        })
        .catch(() => {
          this.chartOptions = null;
        });
    },
    // 查询主页我的任务
    getCompletedTask(params, apiType, chartOption) {
      projectApi[apiType](params)
        .then((res) => {
          this.chartOptions = null;
          if (res.data?.length > 0) {
            this.chartOptions = chartConfig[chartOption];
            let data1 = [],
              data2 = [],
              data3 = [];
            res.data.forEach((item) => {
              data1.push(item.doneNum);
              data2.push(item.projectname);
              data3.push(item.undoneNum);
            });
            this.chartOptions.series[0].data = data1;
            this.chartOptions.series[1].data = data3;
            this.chartOptions.xAxis[0].data = data2;
            this.chartOptions.title.text = this.$i18n.t(
              "label.projectManagement.completion"
            );
            this.chartOptions.series[0].name = this.$i18n.t(
              "label.projectManagement.completed.quantity"
            );
            this.chartOptions.series[1].name = this.$i18n.t(
              "label.projectManagement.unfinished.quantity"
            );
            this.chartOptions.legend.data = [
              this.$i18n.t("label.projectManagement.completed.quantity"),
              this.$i18n.t("label.projectManagement.unfinished.quantity"),
            ];
          }
        })
        .catch(() => {
          this.chartOptions = null;
        });
    },

    // 查询主页问题状态
    getProblemStatus(params, apiType, chartOption) {
      projectApi[apiType](params)
        .then((res) => {
          this.chartOptions = null;
          if (res.data?.length > 0) {
            this.chartOptions = chartConfig[chartOption];
            let data1 = [],
              data2 = [],
              count = 0;
            res.data.forEach((item) => {
              if (
                item.status == "打开" ||
                item.status == "已逾期" ||
                item.status == "处理中" ||
                item.status == "等待测试"
              ) {
                if (item.num === 0) {
                  count++;
                }
                data1.push(item.status + "-" + item.num);
                data2.push({
                  value: item.num,
                  name: item.status + "-" + item.num,
                });
              }
            });
            this.chartOptions.legend.data = data1;
            this.chartOptions.series[0].data = data2;
            if (count === 4) {
              this.chartOptions = null;
            }
          }
        })
        .catch(() => {
          this.chartOptions = null;
        });
    },
    //根据id查询项目
    getIdProjectInfo(params, chartOption) {
      projectApi
        .getIdProjectInfo(params)
        .then((res) => {
          this.chartOptions = null;
          if (res.data?.length > 0) {
            this.chartOptions = chartConfig[chartOption];
            let data1 = [],
              data2 = [];
            res.data.forEach((item) => {
              data1.push(
                item.completePercentage
                  ? item.completePercentage.split("%")[0] * 1
                  : 0
              );
              data2.push(item.name);
            });
            this.chartOptions.series[0].data = data1;
            this.chartOptions.xAxis[0].data = data2;
          }
        })
        .catch(() => {
          this.chartOptions = null;
        });
    },
  },
  created() {},
  mounted() {
    switch (this.chartItem.i) {
      case "Achievement-card1":
        this.cardTitle = this.$i18n.t("label.projectManagement.myproject"); //我的项目
        this.titleWord = this.$i18n.t("label.projectManagement.nodata.project"); //"Oppos~没有找到相关数据，快来创建与我相关的项目吧！"
        this.imgSrc = "icon-myProject_nodata";
        this.getData({ name: "" }, "getProjectInfo", "homeOption1");
        break;
      case "CardView-card4":
        this.cardTitle = this.$i18n.t("label.mytask"); //我的任务;
        this.titleWord = this.$i18n.t("label.projectManagement.nodata.task"); //"还没有任务的记录~<br/>快来添加任务信息吧！"
        this.imgSrc = "icon-task_nodata";
        this.getCompletedTask({}, "getCompletedTask", "homeOption2");
        break;
      case "CardView-card9":
        this.cardTitle = this.$i18n.t("label.projectManagement.problem.status"); // "问题状态";
        this.titleWord = this.$i18n.t("label.projectManagement.nodata.problem"); // "太棒了，没有棘手的问题需要解决！"
        this.imgSrc = "icon-problem_nodata";
        this.getProblemStatus(
          { projectId: "" },
          "getProblemStatus",
          "homeOption4"
        );
        break;
      case "Achievement-card2":
        this.cardTitle = this.$i18n.t("label.projectManagement.resource"); //"资源管理";
        this.titleWord = this.$i18n.t(
          "label.projectManagement.nodata.resource"
        ); //"还没参与到任何项目中。<br/>在成员中添加‘我‘的项目信息，即可查看团队成员的时间分配了！"
        this.imgSrc = "icon-resource_nodata";
        this.getMemberWorkHour(
          { projectId: "" },
          "getMemberWorkHour",
          "homeOption5"
        );
        break;
      default:
        this.cardTitle = "";
        this.chartOptions = {};
        break;
    }
  },
};
</script>
<style lang="scss" scoped>
.cardPro {
  height: 100%;
}
.box-card {
  height: 100%;
  ::v-deep .el-card__header {
    padding: 20px 20px 10px 20px;
    border: none;
  }
  ::v-deep .el-card__body {
    padding: 10px 20px;
    height: calc(100% - 60px);
  }
  .cardContainer {
    height: 100%;
    .chartContainer {
      width: 100%;
      height: 100%;
    }
    .noData {
      text-align: center;
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .cardHead {
    display: flex;
    i {
      font-size: 20px;
      margin-bottom: 3px;
    }
    span {
      color: #0e0d0d;
    }
    .downIcon {
      display: flex;
      cursor: pointer;
      align-items: center;
    }
  }
}
::v-deep .chartContainer div {
  cursor: default !important;
}
</style>
