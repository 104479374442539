// 首页--任务状态--柱状图
const homeOption1 = {
  title: {
    text: "完成百分比",
    top:20,
    //left: 10
    textStyle: {
      color: "#888888",
      fontWeight: 500,
      fontSize: 12,
    },
  },
  tooltip: {
    trigger: "axis",
    formatter: "{c}%",
    axisPointer: {
      type: "cross",
      label: {
        backgroundColor: "#6a7985",
      },
    },
  },
  // legend: {
  //   data: ["邮件营销", "联盟广告", "视频广告", "直接访问", "搜索引擎"],
  // },
  // toolbox: {
  //   feature: {
  //     saveAsImage: {},
  //   },
  // },
  grid: {
    left: "0",
    right: "0",
    bottom: "8%",
    containLabel: true,
  },
  xAxis: [
    {
      type: "category",
      // boundaryGap: false,
      data: [],
      axisLabel: {
        interval: 0,
        show: true,
        textStyle: {
          color: "#666666",
        },
        lineHeight: 25,
        formatter: (value, index) =>{
          if (index % 2 != 0) {
            return "\n " + (value.length>5?(value.slice(0,5)+"..."):value);
          } else {
            return value.length>5?(value.slice(0,5)+"..."):value;
          }
        },
      },
      splitLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          type: "solid",
          color: "#DCDCDC", //左边线的颜色
          width: "1", //坐标线的宽度
        },
      },
    },
  ],
  yAxis: [
    {
      show: true,
      type: "value",
      max: 100,
      // boundaryGap: ['0%', '10%','20%','30%','40%',"50",'60%','70%','80%','90%','100%'],
      axisLabel: {
        show: true,
        textStyle: {
          color: "#666666",
        },
        formatter: "",
      },
      //去除网格线
      splitLine: {
        show: false,
      },
      // axisLine:{
      //   show:false
      // },
      axisTick: {
        show: false,
      },
      axisLine: {
       show: true,
        lineStyle: {
          type: "solid",
          color: "#DCDCDC", //左边线的颜色
          width: "1", //坐标线的宽度
        },
      },
    },
  ],
  series: [
    {
      name: "",
      type: "bar",
      stack: "总量",
      areaStyle: {},
      barWidth: 30,
      itemStyle: {
        normal: {
          color: function(params) {
            // build a color map as your need.
            const colorList = [
              "#FCB95C",
              "#7F8DE1",
              "#2DCBBE",
              "#2D90CB",
              "#AFCB2D",
              "#CB6A2D"];

            return colorList[params.dataIndex%6];
          }, //以下为是否显示，显示位置和显示格式的设置了
          label: {
            show: true,
            position: "top",
            formatter: "{c}%",
            //  formatter: '{b}\n{c}'
          },
        },
      },
      data: [],
    },
  ],
};

const homeOption2 = {
  title: {
    text: "完成情况",
    top:20,
    //left: 10
    textStyle: {
      color: "#888888",
      fontWeight: 500,
      fontSize: 12,
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      label: {
        backgroundColor: "#6a7985",
      },
    },
  },
  legend: {
    itemWidth:15,  
    itemHeight:15,  
    data:['完成数量','未完成数量'],
    left: "70%",
    top: "0%",
  },
  // toolbox: {
  //   feature: {
  //     saveAsImage: {},
  //   },
  // },
  grid: {
    left: "0",
    right: "0",
    bottom: "8%",
    containLabel: true,
  },
  xAxis: [
    {
      type: "category",
      //boundaryGap: false,
      data: [
      ],
      axisLabel: {
        interval: 0,
        show: true,
        textStyle: {
          color: "#666666",
        },
        lineHeight: 25,
        formatter: (value, index) =>{
          if (index % 2 != 0) {
            return "\n " + (value.length>5?(value.slice(0,5)+"..."):value);
          } else {
            return value.length>5?(value.slice(0,5)+"..."):value;
          }
        },
      },
      axisLine: {
        show: true,
        lineStyle: {
          type: "solid",
          color: "#DCDCDC", //左边线的颜色
          width: "1", //坐标线的宽度
        },
      },
      splitLine: {
        show: false,
      },
      // axisLine:{
      //   show:false
      // },
      axisTick: {
        show: false,
      },
    },
  ],
  yAxis: [
    {
      type: "value",
      splitLine: {
        show: false,
      },
      // axisLine:{
      //   show:false
      // },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#666666",
        },
        formatter: "",
      },
      axisLine: {
        show: true,
        lineStyle: {
          type: "solid",
          color: "#DCDCDC", //左边线的颜色
          width: "1", //坐标线的宽度
        },
      },
    },
  ],
  series: [
    {
      name: '完成数量',
      type: 'bar',
      stack:'使用情况',
      barWidth: 30,
      data: [],
      itemStyle:{
           normal:{color:"#97c3e8"},
      }
  },{
      name: '未完成数量',
      type: 'bar',
      stack:'使用情况',
      barWidth: 30,
      data: [],
      itemStyle:{
           normal:{color:"#9cd88d"},
      }
  }
  ],
}
const homeOption5 = {
  title: {
    text: "",
    //left: 10
    top:20,
    textStyle: {
      color: "#888888",
      fontWeight: 500,
      fontSize: 12,
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "cross",
      label: {
        backgroundColor: "#6a7985",
      },
    },
  },
  // legend: {
  //   data: [],
  // },
  // toolbox: {
  //   feature: {
  //     saveAsImage: {},
  //   },
  // },
  grid: {
    left: 0,
    right: 80,
    bottom: "3%",
    containLabel: true,
  },
  xAxis: [
    {
      type: "value",
     // boundaryGap: false,
     name: '天数',//以下是轴的名字和样式
     nameTextStyle: {
          fontStyle: "normal",
          color: "#666666",
          align: "left",
          verticalAlign: "top",
          lineHeight: 26
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#666666",
        },
      },
      axisLine: {
        show: true,
        lineStyle: {
          type: "solid",
          color: "#DCDCDC", //左边线的颜色
          width: "1", //坐标线的宽度
        },
      },

      splitLine: {
        show: false,
      },
      // axisLine:{
      //   show:false
      // },
      axisTick: {
        show: false,
      },
    },
  ],
  yAxis: [
    {
      type: "category",
      minInterval:0,
      // splitLine: {
      //   show: false,
      // },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#666666",
        },
       // formatter:‘’ ,
      },
      axisLine: {
        show: true,
        lineStyle: {
          type: "solid",
          color: "#DCDCDC", //左边线的颜色
          width: "1", //坐标线的宽度
        },
      },
      data: [],
    },
  ],
  series: [
    {
      name: "",
      type: "bar",
      stack: "总量",
      areaStyle: {},
      barWidth: 30,
      itemStyle: {
        normal: {
          color: function(params) {
            // build a color map as your need.
            var colorList = [
              "#FCB95C",
              "#7F8DE1",
              "#2DCBBE",
              "#2D90CB",
              "#AFCB2D",
              "#CB6A2D"];
            return colorList[params.dataIndex%6];
          }, //以下为是否显示，显示位置和显示格式的设置了

          label: {
            show: true,
            position: "right",
            formatter: '{c}天'
            //  formatter: '{b}\n{c}'
          },
        },
      },
      data: [],
    },
  ],
};
// 首页--任务状态--饼图
const homeOption4 = {
  tooltip: {
    trigger: "item",
    formatter: "{a} <br/>{b} : {c} ({d}%)",
  },
  legend: {
    orient: "horizontal",
    left: "center",
    data: [],
  },
  color: ["#F6817B", "#FEC367", "#DEDCDA", "#62A5F3"],
  series: [
    {
      name: "问题状态",
      type: "pie",
      radius: "55%",
      center: ["50%", "60%"],
      data: [],
      labelLine: {
        show: false,
      },
      label: {
        show: false,
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};
export { homeOption1, homeOption2, homeOption4,homeOption5};
