<!-- cardPro -->
<template>
  <div class="cardPro">
    <el-card class="box-card">
      <div slot="header" class="clearfix cardHead">
        <div class="hoverBtnBox" v-if="showDownIcon">
          <el-popover
            placement="bottom"
            v-model="visible"
            width="200"
            trigger="hover"
          >
            <DownList
              @handleItem="handleItem"
              :loading="downLoading"
              :itemlist="downItemlist"
              :key="key"
              @searchDownList="searchDownList"
            />
            <div slot="reference" class="downIcon">
              <span>{{ cardTitle }}</span>
              <i class="el-icon-caret-bottom"></i>
            </div>
          </el-popover>
        </div>
      </div>
      <div class="cardContainer">
        <!-- 暂无数据时显示这个div -->
        <div class="noData" v-if="tableOptions.length == 0">
         <temporarily-no-data :titleWord="titleWord" :imgSrc="imgSrc" />
        </div>
        <el-table
          :data="tableOptions"
          style="width: 100%"
          class="noTableHead"
          v-else
        >
          <el-table-column
            show-overflow-tooltip
            v-for="(item, index) in tableColumns"
            :key="index"
            :prop="item.props"
            :min-width="item.width"
          >
            <template slot-scope="scope">
              <span
                v-if="item.showDetail"
                type="primary"
                class="link"
                @click.prevent="showDetail(item.props, scope.row)"
                >{{ scope.row[item.props] }}
              </span>
              <span v-else :class="item.hasColorTxt ? 'redColor' : ''">{{
                scope.row[item.props]
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>

<script>
import DownList from "./downList";
//api
import * as projectApi from "./api.js";
import TemporarilyNoData from "@/components/TemporarilyNoData/index.vue"; // 暂无数据
export default {
  name: "cardPro",
  components: { DownList, TemporarilyNoData },
  data() {
    return {
      tableOptions: [],
      tableColumns: [],
      cardTitle: "",
      showDownIcon: true,
      visible: false,
      downItemlist: [{ name:this.$i18n.t("label.projectManagement.recent.projects"), id: "currentPro" }],//最近的项目
      key: "",
      downLoading: false,
      titleWord:"",
      imgSrc:""
    };
  },
  props: {
    tableItem: {
      type: Object,
      default: () => {},
    },
  },
  computed: {},
  watch: {
    visible(val) {
      if (val) {
        this.key = 1;
      } else {
        this.key = 2;
        this.downItemlist = [{ name: this.$i18n.t("label.projectManagement.recent.projects"), id: "currentPro" }];//最近的项目
        this.downLoading = false;
      }
    },
  },
  methods: {
    showDetail(prop, row) {
      let pathId = "",realId="";
      
      switch (prop) {
        case "pname":
          pathId = row.id;
          realId="cloudcc_project"
          break; //团队成员-项目
        case "cname":
          pathId = row.mid;
          break; //团队成员-成员
        case "taskName":
          pathId = row.taskid;
          break; //逾期任务-任务
        case "name":
          pathId = row.taskid;
          break; //本周工作-任务
        case "milestonename":
          pathId = row.milestoneid;
          break; //本周工作-里程牌
        case "projectname":
          pathId = row.projectid;
          break; //逾期任务-项目
      }
      this.$router.push({
        path: `/commonObjects/detail/${pathId}/DETAIL`,
        query: {
         objId:realId,
        },
      });
    },
    searchDownList(val) {
      if (val) {
        this.downLoading = true;
        this.getDownList({ name: val }, "getProjectInfo");
      } else {
        this.downItemlist = [{ name: this.$i18n.t("label.projectManagement.recent.projects"), id: "currentPro" }];//最近的项目
      }
    },
    handleItem(val) {
      this.visible = false;
      if (val.id === "currentPro") {
        switch (this.tableItem.i) {
          case "CardView-card8": //团队成员
            this.getProjectMemberList({ name: "" });
            break;
          case "CardView-card6": //已逾期的任务
            this.getOverdueTask({ projectId: "" }, "getOverdueTask");
            break;
          case "CardView-card7": //我本周的工作
            this.getThisWeekTask({ projectId: "" }, "getThisWeekTask");
            break;
          default:
            break;
        }
      } else {
        switch (this.tableItem.i) {
          case "CardView-card8": //团队成员
            this.getProjectIdMember({ projectId: val.id, judeg: false });
            break;
          case "CardView-card6": //已逾期的任务
            this.getOverdueTask({ projectId: val.id }, "getOverdueTask");
            break;
          case "CardView-card7": //我本周的工作
            this.getThisWeekTask({ projectId: val.id }, "getThisWeekTask");
            break;
          default:
            break;
        }
      }
    },

    // 查询下拉项目数据
    getDownList(params, apiType) {
      projectApi[apiType](params)
        .then((res) => {
          if (res.data?.length > 0) {
            this.downItemlist = res.data.map((item) => {
              return item;
            });
          }
          this.downLoading = false;
        })
        .catch(() => {
          this.downItemlist = [];
          this.downLoading = false;
        });
    },
    // 查询主页获取团队成员数据
    getProjectMemberList(params) {
      projectApi
        .getProjectMemberList(params)
        .then((res) => {
          this.tableOptions = [];
          if (res.data?.length > 0) {
            res.data.forEach((element) => {
              element.value.forEach((arry) => {
                this.tableOptions.push(arry);
              });
            });
          }
        })
        .catch(() => {
          this.tableOptions = [];
        });
    },
    //根据项目id获取成员信息
    getProjectIdMember(params) {
      projectApi
        .getProjectIdMember(params)
        .then((res) => {
          this.tableOptions = [];
          if (res.data?.length > 0) {
            res.data.forEach((element) => {
              element.value.forEach((arry) => {
                this.tableOptions.push(arry);
              });
            });
          }
        })
        .catch(() => {
          this.tableOptions = [];
        });
    },
    // 查询主页逾期任务数据
    getOverdueTask(params, apiType) {
      projectApi[apiType](params)
        .then((res) => {
          this.tableOptions = [];
          if (res.data?.length > 0) {
            res.data.forEach((element) => {
              // let date= element.enddate.replace(/-/g,"/"); //格式为“yyyy-mm-dd”在Chrome正常，但在IE或Firefox则不支持，先将格式转换为“yyyy/mm/dd”
              // element.enddate = this.$options.filters['dateFormat'](new Date(date),$cookies.get('countryCode'));
              element.overDueDay = this.$i18n.t("label.weixin.postunsolve") + element.overDueDay +this.$i18n.t("label.chatter.day");//逾期多少天
              
            });
            this.tableOptions = res.data;
          }
        })
        .catch(() => {
          this.tableOptions = [];
        });
    },
    // 查询主页本周工作
    getThisWeekTask(params, apiType) {
      projectApi[apiType](params)
        .then((res) => {
          this.tableOptions = [];
          if (res.data?.length > 0) {
            this.tableOptions = res.data;
          }
        })
        .catch(() => {
          this.tableOptions = [];
        });
    },
  },
  created() {
    switch (this.tableItem.i) {
      case "CardView-card6":
        this.cardTitle = this.$i18n.t("label.projectManagement.overdue.tasks");//"已逾期的任务"
        this.titleWord = this.$i18n.t("label.projectManagement.nodata.overdue.tasks");//"太棒了，没有逾期的任务!"
        this.imgSrc ="icon-task_nodata"
        this.tableColumns = [
          { id: 1, props: "taskName", width: "130px", showDetail: true },
          { id: 2, props: "projectname", width: "130px", showDetail: true },
          { id: 3, props: "principal", width: "130px" },
          { id: 4, props: "enddate", width: "130px" },
          { id: 5, props: "overDueDay", width: "80px", hasColorTxt: true },
        ];
        this.getOverdueTask({ projectId: "" }, "getOverdueTask");
        break;

      case "CardView-card7":
        this.cardTitle = this.$i18n.t("label.projectManagement.week.work");//"我本周的工作";
        this.titleWord = this.$i18n.t("label.projectManagement.nodata.week.work");//"快来添加任务以便更好地完成本周的工作吧~"
        this.imgSrc ="icon-task_nodata"
        this.tableColumns = [
          { id: 1, props: "name", width: "130px", showDetail: true },
          { id: 2, props: "milestonename", width: "130px", showDetail: true },
          { id: 3, props: "enddate", width: "80x" },
        ];
        this.getThisWeekTask({ projectId: "" }, "getThisWeekTask");
        break;

      case "CardView-card8":
        this.cardTitle = this.$i18n.t("label.projectManagement.team.members");//团队成员
        this.titleWord =this.$i18n.t("label.projectManagement.nodata.team.members");// "没有找到我的项目成员~"
        this.imgSrc ="icon-member_nodata"
        this.tableColumns = [
          { id: 1, props: "cname", width: "130px", showDetail: true },
          { id: 2, props: "pname", width: "130px", showDetail: true },
          { id: 3, props: "role", width: "60px" },
        ];
        this.getProjectMemberList({ name: "" });
        break;

      default:
        this.cardTitle = "";
        this.tableOptions = [];
        break;
    }
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.cardPro {
  height: 100%;
}
.box-card {
  height: 100%;
  ::v-deep .el-card__header {
    padding: 20px 20px 10px 20px;
    border: none;
  }
  ::v-deep .el-card__body {
    padding: 10px 20px;
    height: calc(100% - 60px);
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
    overflow: hidden;
  }
  .cardHead {
    display: flex;
    i {
      font-size: 20px;
      margin-bottom: 3px;
    }
    span {
      color: #0e0d0d;
    }
    .downIcon {
      display: flex;
      cursor: pointer;
      align-items: center;
    }
  }
  .cardContainer {
    height: 100%;
    overflow-y: auto;
    .noData {
      text-align: center;
      position: absolute;
      width:100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .noTableHead {
      ::v-deep thead {
        display: none;
      }
    }
    ::v-deep .el-table--border::after,
    .el-table--group::after,
    .el-table::before {
      background: none;
    }
    ::v-deep .el-table td {
      border: none !important;
      padding: 5px 0 !important;
      font-size: 12px;
      .redColor {
        color: red;
      }
    }
    .link {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      color: #409EFF;
      cursor: pointer;
    }
  }
  /*滚动条整体样式*/
  ::v-deep .cardContainer::-webkit-scrollbar {
    width: 10px; /*宽分别对应竖滚动条的尺寸*/
    height: 14px; /*高分别对应横滚动条的尺寸*/
  }

  /*滚动条里面轨道*/
  ::v-deep .cardContainer::-webkit-scrollbar-track {
    border-radius: 0 !important;
    background-color: rgba(0, 0, 0, 0.2);
    background: #d8d8d8;
  }
  /*滚动条里面拖动条*/
  ::v-deep .cardContainer::-webkit-scrollbar-thumb {
    background-color: #969696;
    border-radius: 0 !important;
  }
}
</style>
