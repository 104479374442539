<template>
  <div class="cardPro">
    <el-card class="box-card">
      <div slot="header" class="clearfix cardHead">
        <div class="hoverBtnBox">
          <el-popover
            placement="bottom"
            v-model="visible"
            width="200"
            trigger="hover"
          >
            <DownList
              @handleItem="handleItem"
              :loading="downLoading"
              :itemlist="downItemlist"
              :key="key"
              @searchDownList="searchDownList"
            />
            <div slot="reference" class="downIcon">
              <!-- 甘特图 -->
              <span>{{$t('label.projectManagement.gantt')}}</span>
              <i class="el-icon-caret-bottom"></i>
            </div>
          </el-popover>
        </div>
      </div>
      <div class="cardContainer">
        <!-- 暂无数据时显示这个div -->
        <div class="noData" v-if="tasks.data.length == 0">
          <temporarily-no-data />
        </div>
        <div v-else class="ganttContainer">
          <GanttComponent
            ref="ganttchart"
            class="left-container"
            :tasks="tasks"
            @task-updated="logTaskUpdate"
            @link-updated="logLinkUpdate"
            @task-selected="selectTask"
            style="height: 100%"
          />
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import DownList from "./downList";
import GanttComponent from "@/views/gantt/ganttcontent.vue";
import * as projectApi from "./api.js";
import TemporarilyNoData from "@/components/TemporarilyNoData/index.vue"; // 暂无数据
export default {
  name: "gantt",
  components: { DownList, GanttComponent, TemporarilyNoData },
  props: {
    ganttItem: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visible: false,
      downItemlist: [{ name:this.$i18n.t("label.projectManagement.recent.projects"), id: "currentPro" }],//最近的项目
      key: "",
      downLoading: false,
      tasks: {
        data: [
          // {id: 1, text: '我是任务1', start_date: '2020-11-17', duration: 16, user:'小张',progress: 0.6,color:"#006DCC",isshow:false},
          // {id: 2, text: 'Task #2', start_date: '2020-11-20', duration:6, user:'小王',progress: 0.6,color:"#F6817B"},
          // {id:3,  text:"我是1的子任务", start_date:"2021-8-17", duration:8, user:'小李',progress:0.6,parent:1,color:"#195594"}, //添加子任务
        ],
        links: [
          // {id: 1, source: 1, target: 2, type: '0'}
        ],
      },
      selectedTask: null,
    };
  },
  watch: {
    visible(val) {
      if (val) {
        this.key = 1;
      } else {
        this.key = 2;
        this.downItemlist = [{name:this.$i18n.t("label.projectManagement.recent.projects"), id: "currentPro" }];//最近的项目
        this.downLoading = false;
      }
    },
  },
  mounted() {
    if (this.$refs.ganttchart) {
      this.$refs.ganttchart.reload(); // 重新刷新gantt图
    }
    this.getFirstGanttData();
  },
  methods: {
    //获取主页甘特图
    getPIdMilestone(params) {
      projectApi
        .getPIdMilestone(params)
        .then((res) => {
          this.tasks = { data: [], links: [] };
          if (res.data.tasks.data?.length > 0) {
             for(let i in res.data.tasks.data){
                res.data.tasks.data[i].end_date = this.$moment(res.data.tasks.data[i].end_date).subtract(-1, 'days').format("YYYY-MM-DD");
             }
            this.tasks = res.data.tasks;
          }
          this.$nextTick(() => {
            this.$refs.ganttchart.reload();
          });
        })
        .catch(() => {
          this.tasks = { data: [], links: [] };
        });
    },
    searchDownList(val) {
      if (val) {
        this.downLoading = true;
        this.getDownList({ name: val });
      } else {
        this.downItemlist = [{ name:this.$i18n.t("label.projectManagement.recent.projects"), id: "currentPro" }];//最近的项目
      }
    },
    handleItem(val) {
      this.visible = false;
      if (val.id === "currentPro") {
        this.getFirstGanttData();
      } else {
        this.getPIdMilestone({ parentId: val.id });
      }
    },
    // 初次查询最近项目并获取最近项目对应的甘特图
    async getFirstGanttData() {
      let result = await projectApi.getProjectInfo({ name: "" });
      if (result.data?.length > 0) {
        let id = result.data[0].id;
        this.getPIdMilestone({ parentId: id });
      }
    },
    selectTask(task) {
      this.selectedTask = task;
    },

    addMessage(message) {
      this.messages.unshift(message);
      if (this.messages.length > 40) {
        this.messages.pop();
      }
    },

    logTaskUpdate(id, mode, task) {
      let text = task && task.text ? ` (${task.text})` : "";
      let message = `Task ${mode}: ${id} ${text}`;
      this.addMessage(message);
    },

    logLinkUpdate(id, mode, link) {
      let message = `Link ${mode}: ${id}`;
      if (link) {
        message += ` ( source: ${link.source}, target: ${link.target} )`;
      }
      this.addMessage(message);
    },
    // 查询下拉项目数据
    getDownList(params) {
      projectApi
        .getProjectInfo(params)
        .then((res) => {
          if (res.data?.length > 0) {
            this.downItemlist = res.data.map((item) => {
              return item;
            });
          }
          this.downLoading = false;
        })
        .catch(() => {
          this.downItemlist = [];
          this.downLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.cardPro {
  height: 100%;
}
.box-card {
  height: 100%;
  ::v-deep .el-card__header {
    padding: 20px 20px 10px 20px;
    border: none;
  }
  ::v-deep .el-card__body {
    padding: 10px 20px;
    height: calc(100% - 60px);
  }
  .cardContainer {
    height: 100%;
    .noData {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .ganttContainer {
      width: 100%;
      height: 100%;
    }
  }

  .cardHead {
    display: flex;
    i {
      font-size: 20px;
      margin-bottom: 3px;
    }
    span {
      color: #0e0d0d;
    }
    .downIcon {
      display: flex;
      cursor: pointer;
      align-items: center;
    }
  }
}
</style>
