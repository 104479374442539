<!-- projectManagement -->
<template>
  <div class="projectManagement">
    <Header-card
      userName="王某某"
      companyName="北京神州云动科技股份有限公司"
      :selectTitleName="$t('label.profile.mobile.setup.stand')"
      @refreshEvent="refreshEvent"
      :isAdmin="true"
      :rolePageDropDownName="rolePageDropDownName"
    />
    <grid-layout
      :layout.sync="layout"
      :col-num="12"
      :row-height="80"
      :is-draggable="isEditStatus"
      :is-resizable="isEditStatus"
      :is-mirrored="false"
      :vertical-compact="true"
      :margin="[10, 10]"
      :use-css-transforms="true"
      :max-rows="maxRows"
    >
      <!-- 背景的方格子布局循环事件
        非编辑 状态不显示格子
    -->
      <div class="layout-test" v-if="isEditStatus">
        <div
          class="layout-child"
          v-for="(item, index) in squareGridNum"
          :key="index"
        ></div>
      </div>
      <!-- 
      i 栅格中元素的ID
      x 标识栅格元素位于第几列，需为自然数
      y 标识栅格元素位于第几行，需为自然数
      w 标识栅格元素的初始宽度，值为colWidth的倍数
      h 标识栅格元素的初始高度，值为rowHeight的倍数
    -->
      <grid-item
        v-for="item in layout"
        :key="item.i"
        :x="Number(item.x)"
        :y="Number(item.y)"
        :w="Number(item.w)"
        :h="Number(item.h)"
        :i="item.i"
        :min-w="Number(item.minW)"
        :min-h="Number(item.minH)"
        @moved="movedEvent"
        @resize="resizedEvent"
        @layoutUpdated="layoutUpdatedEvent"
      >
        <!-- 项目管理卡片--图表 -->
        <ProjectChart :chartItem="item" v-if="item.type === 'ProjectChart'" />
        <!-- 项目管理卡片--表格 -->
        <ProjectTableList
          :tableItem="item"
          v-if="item.type === 'ProjectTableList'"
        />
        <!-- 项目管理卡片--甘特图 -->
        <ProjectGantt :ganttItem="item" v-if="item.type === 'ProjectGantt'" />
      </grid-item>
    </grid-layout>
  </div>
</template>

<script>
/**
 * 项目管理
 */
import HeaderCard from "@/views/homePage/components/HeaderCard.vue";
// 主页拖拽插件
import VueGridLayout from "vue-grid-layout";
// 项目管理---图例卡片
import ProjectChart from "./ProjectCard/Chart";
// 项目管理---表格卡片
import ProjectTableList from "./ProjectCard/TableList";
// 项目管理---甘特图
import ProjectGantt from "./ProjectCard/Gantt";
export default {
  name: "projectManagement",
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    ProjectChart,
    ProjectTableList,
    ProjectGantt,
    HeaderCard,
  },
  data() {
    return {
      rolePageDropDownName: "",
      isEditStatus: false,
      /**
       * 组件的数据，需要深克隆数据，
       * 可以在 当前组件随意拖动位置，
       *以至于可以最后将移动后的数据发送后端
       */
      layout: [
        // 1我的项目
        {
          x: 0,
          y: 0,
          w: 8,
          h: 5,
          minW: 8,
          minH: 5,
          i: "Achievement-card1",
          type: "ProjectChart",
        },
        // 2资源管理
        {
          x: 8,
          y: 0,
          w: 4,
          h: 5,
          minW: 4,
          minH: 5,
          i: "Achievement-card2",
          type: "ProjectChart",
        },
        // 3甘特图
        {
          x: 0,
          y: 7,
          w: 12,
          h: 6,
          minW: 12,
          minH: 6,
          i: "Achievement-card3",
          type: "ProjectGantt",
        },
        // 4我完成的任务
        {
          x: 0,
          y: 17,
          w: 6,
          h: 5,
          minW: 4,
          minH: 5,
          i: "CardView-card4",
          type: "ProjectChart",
        },
        // 5已逾期的任务
        {
          x: 6,
          y: 17,
          w: 6,
          h: 5,
          minW: 4,
          minH: 5,
          i: "CardView-card6",
          type: "ProjectTableList",
        },
        // 6我本周的工作
        {
          x: 0,
          y: 24,
          w: 4,
          h: 5,
          minW: 4,
          minH: 5,
          i: "CardView-card7",
          type: "ProjectTableList",
        },

        // 7问题状态
        {
          x: 4,
          y: 24,
          w: 4,
          h: 5,
          minW: 4,
          minH: 5,
          i: "CardView-card9",
          type: "ProjectChart",
        },
        // 8团队成员
        {
          x: 8,
          y: 24,
          w: 4,
          h: 5,
          minW: 4,
          minH: 5,
          i: "CardView-card8",
          type: "ProjectTableList",
        },
      ],
      //当前组件缩放后，保存布局给仪表板组件缩放用（测试中，可删）
      currentResizedLayout: {},
    };
  },
  computed: {
    /**
     * 网格的背景布局计算规则
     * （每行12格*总共数组的长度）
     */
    squareGridNum() {
      return 50 * 12;
    },
    /**
     * 网格的最大行数限制
     * 暂时固定50，因为zoho是 46格
     */
    maxRows() {
      return this.squareGridNum / 12;
    },
  },
  watch: {
    // 监听数据变化
    commonDataArr: {
      handler(val) {
        this.layout = val;
      },
      deep: true,
    },
  },
  methods: {
    // 全局刷新
    refreshEvent() {
      this.$bus.$emit("refreshOverallData");
    },
    // 移动后的事件
    movedEvent() {
      //
    },
    // 调整大小后的事件
    resizedEvent(i, newH, newW, newHPx, newWPx) {
      //
      // 当前组件缩放后，保存布局给仪表板组件缩放用（测试中，可删）
      this.currentResizedLayout = { i, newH, newW, newHPx, newWPx };

      this.$bus.$emit("q");
    },
    // 更新事件（布局更新或栅格元素的位置重新计算）
    layoutUpdatedEvent() {
      //
    },
  },
  created() {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "@/style/cardStyle.scss";

.item {
  background-color: #ccc;
  height: 100%;
}

.vue-grid-layout {
  margin-left: -10px;
  margin-right: -10px;
}

.vue-grid-item {
  top: -10px;
  overflow: hidden;
}
/* 拖动块的阴影颜色 */
::v-deep .vue-grid-item.vue-grid-placeholder {
  background-color: #b6d5fc !important;
  opacity: 0.9;
}

.layout-test {
  margin-left: 10px;
  .layout-child {
    float: left;
    width: calc((100% - 120px) / 12);
    box-sizing: border-box;
    height: 80px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-color: #e4f0ff;
    z-index: -1;
  }
}
.projectManagement {
  margin: 10px;
  ::v-deep .vue-grid-layout {
    margin-top: 72px;
  }
}
</style>